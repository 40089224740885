.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(0px 4.2674px 4.2674px rgba(0, 0, 0, 0.2));
}

.navbar img {
  height: 70px;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 154px 68px;
  margin-left: 30px;
  padding: 5px 0;
}


.button-signout {
  color: #fff;
  font-weight: 400;
  border-radius: 5px;
  border-width: 1px;
  padding: 20px 30px;
  font-size: 1rem;
  background-color: #00698f;
  border-color: #00698f;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.button-signout:hover {
  background-color: #fff;
  color: #00698f;
}

.button-signout {
  margin-top: 0;
  margin-right: 30px;
  width: 180px;
}