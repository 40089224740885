.signup {
  background: url(https://estudiolmg.com.ar/assets/img/bkg_home_hero@2x.jpg) 50% no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

h3 {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
}

h2 {
  font-size: 5.625rem;
  color: #fff;
  letter-spacing: 0;
  margin-bottom: 30px!important;
  font-weight: 100!important;
  font-style: italic;
}

.lead {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 50px;
  font-weight: 300;
}

.signupForm {
  display: grid;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
}

.signup button {
  color: #fff;
  font-weight: 400;
  border-radius: 5px;
  border-width: 1px;
  padding: 20px 30px;
  font-size: 1rem;
  background-color: #00698f;
  border-color: #00698f;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-top: 20px;
}

.signup button:hover {
  background-color: #fff;
  color: #00698f;
}