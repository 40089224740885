.logo-home {
  position: absolute;
  top: 10px;
  padding: 4px;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 8px;
}

.logo-home img {
  width: 200px;
}

.sub-title {
  text-align: right;
  font-style: normal;
  font-size: 2.8em;
  margin-top: -38px;
  margin-bottom: 40px!important;
}