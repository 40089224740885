html, body {
  font-family: Raleway,sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container,.container-sm {
      max-width:540px
  }
}

@media (min-width: 768px) {
  .container {
      max-width:720px
  }
}

@media (min-width: 992px) {
  .container {
      max-width:960px
  }
}

@media (min-width: 1200px) {
  .container {
      max-width:1140px
  }
}

@media (min-width: 1400px) {
  .container {
      max-width:1320px
  }
}

@media (min-width: 1600px) {
  .container {
      max-width:1500px
  }
}

h1, h2, h3, h4, h5 {
  /* font-family: Raleway,sans-serif; */
  font-family: Playfair Display,serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

strong {
  font-weight: 700;
}