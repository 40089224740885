.home {
  background: url(https://www.divisionclientes.com.ar/assets/img/img_bg_hero.jpg) 50% no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.home .container {
  background: rgba(0,23,51,.6);
  padding: 50px 20px;
}

.home .title {
  width: fit-content;
  margin: 0 auto;
}

h3 {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
}

h2 {
  font-size: 5.625rem;
  color: #fff;
  letter-spacing: 0;
  margin-bottom: 30px!important;
  font-weight: 100!important;
  font-style: italic;
}

.lead {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 50px;
  font-weight: 300;
}

.buttons button {
  color: #fff;
  font-family: Raleway,sans-serif;
  font-weight: 400;
  border-radius: 5px;
  border-width: 1px;
  padding: 20px 30px;
  font-size: 1.25rem;
  background-color: transparent;
  border-color: #ff0054;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.buttons button:hover {
  background-color: #ff0054;
  color: #fff;
}